<template>
  <div class="cookies">
    <!-- page title -->
    <page-title></page-title>

    <!-- contatti header -->
    <v-container>
      <div class="cookies-header col-md-10 pt-16">
        <p><strong>Dati Piemonte fa uso delle seguenti tipologie di cookie:</strong></p>
        <ul>
          <li>
            <strong>cookie tecnici:</strong>
            <p>sono necessari per consentire la navigazione sicura ed efficiente dei siti; sono cookie di sessione o persistenti, in generale cookie di dominio, come cookie di preferenze utente e cookie di monitoraggio. L'utilizzo dei cookie tecnici viene fatto in modo da evitare il ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione degli utenti, in quanto non consentono l'acquisizione di dati personali identificativi dell'utente.</p>
          </li>
          <li>
            <strong>cookie analytics [Matomo]:</strong>
            <p>al fine di facilitare la navigazione nel sito e il modo in cui i visitatori ci arrivano, il sito utilizza Matomo, un software open source di analisi web che garantisce piena compatibilità con le leggi europee relative alla protezione dei dati. Per analizzare i punti di maggiore interesse dei siti visitati dall'utente, Matomo utilizza i cookie a scopi statistici e raccoglie informazioni in sola forma aggregata, senza associare l'indirizzo IP di navigazione dell'utente a nessun altro dato raccolto precedentemente. Sono stati adottati strumenti che riducono il potere identificativo dei cookie tramite il mascheramento di porzioni significative dell'IP. Sul sito di <a href="https://webanalytics.italia.it/privacy" rel="noopener" target="_blank"><strong>Web Analytics Italia</strong></a> è possibile consultare la policy relativa alla privacy.</p>
            <p>La navigazione su Dati Piemonte comporta l'accettazione delle condizioni d'uso, comprese le funzionalità legate ai cookie che possono essere disabilitate utilizzando le opzioni specifiche presenti nei vari tipi di browser.</p>
          </li>
          <li>
						<strong>cookie di terze parti di profilazione:</strong>
						<p>su alcune pagine di collegamento con i social network e con altre applicazioni multimediali, possono essere impostati altri cookies di terze parti che sono in grado di monitorare il browser e di creare un profilo degli interessi del visitatore. Ciò potrebbe influire su quanto viene visualizzato durante la navigazione su altri siti. Anche questi cookies potrebbero comportare il trasferimento dei dati in paesi extra-europei e, in particolare, negli Stati Uniti.</p>
						<p>In particolare, vengono di seguito elencati i link aggiornati alle informative in materia di cookie, anche al fine di poter disabilitare i relativi cookies installati e conoscere nel dettaglio i tempi di conservazione.</p>
						<ul>
							<li><a href="https://twitter.com/it/privacy" target="_blank">Twitter</a></li>
						</ul>
          </li>
        </ul>
        <p class="mt-5"><strong>Come disabilitare i cookie (opt-out)</strong></p>
        <p>La maggior parte dei browser accettano i cookies automaticamente, ma è possibile rifiutarli. Se non si desidera ricevere o memorizzare i cookie, si possono modificare le impostazioni di sicurezza del browser (Internet Explorer, Google Chrome, Mozilla Firefox, Safari Opera, ecc...). Ciascun browser presenta procedure diverse per la gestione delle impostazioni. Normalmente è necessario accedere alla sezione “opzioni/impostazioni/preferenze avanzate” del browser e qui accedere ad una sezione intitolata “Privacy/Sicurezza/Dati”. A seconda del browser, è possibile bloccare tutti i cookie, scegliere di bloccare solo quelli delle terze parti oppure ancora far sì che i cookie vengano cancellati alla chiusura del browser.</p>
      </div>
    </v-container>
  </div>
</template>

<script>
  import PageTitle from "../components/PageTitle";
  export default {
    name: "Cookies",
    components: {
      PageTitle
    }
  };
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.cookies {
  a {
    color: #2ab5b9;
  }
  .cookies-header {
    font: normal normal normal 21px/40px Open Sans;
    letter-spacing: 0px;
    color: $text-dark;
  }
}
</style>